import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import slugify from "slugify";
// import { Swiper, SwiperSlide } from "swiper/react";

// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import { Autoplay, Navigation, Pagination } from "swiper";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const smooth = "transition-all ease-in-out duration-300";

const query = graphql`
  {
    allContentfulPost(
      sort: { fields: createdAt, order: DESC }
      filter: { highlight: { eq: true } }
      limit: 5
    ) {
      totalCount
      nodes {
        id
        title
        subPath
        date(formatString: "DD MMMM, YYYY")
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
  }
`;

const HighlightPosts = () => {
  const {
    allContentfulPost: { nodes: posts },
  } = useStaticQuery(query);

  return (
    <div className="h-full w-auto group bg-transparent relative flex justify-center items-center ">
      {/* <Swiper
        autoplay={{
          delay: 10000,
        }}
        slidesPerView={"auto"}
        centeredSlides={true}
        navigation={true}
        pagination={true}
        spaceBetween={20}
        modules={[Autoplay, Navigation, Pagination]}
        className="customized-swiper swiper-small"
      >
        {posts.map((post) => {
          const { id, title, image, subPath } = post;
          const pathToImage = getImage(image);
          const slug = slugify(subPath, { lower: true });

          return (
            <SwiperSlide
              key={id}
              className="shadow-lg drop-shadow-xl rounded-xl mb-14"
            >
              <Link to={`/${slug}/`}>
                <GatsbyImage
                  image={pathToImage}
                  alt={title}
                  className={`${smooth} relative image-swiper brightness-[95%] group-hover:brightness-100 rounded-xl`}
                />
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper> */}

      <Carousel
        showArrows={true}
        showStatus={false}
        swipeable={true}
        emulateTouch={true}
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={true}
        centerMode={true}
        centerSlidePercentage={60}
        className="customized-carousel carousel-big hidden md:block"
      >
        {posts.map((post) => {
          const { id, title, image, subPath } = post;
          const pathToImage = getImage(image);
          const slug = slugify(subPath, { lower: true });

          return (
            <div
              key={id}
              className="shadow-lg drop-shadow-xl rounded-xl h-[86%] aspect-square"
            >
              <Link to={`/${slug}/`}>
                <GatsbyImage
                  image={pathToImage}
                  alt={title}
                  className={`${smooth} h-full aspect-square relative image-swiper brightness-[95%] group-hover:brightness-100 rounded-xl`}
                />
              </Link>
            </div>
          );
        })}
      </Carousel>

      <Carousel
        showArrows={true}
        showStatus={false}
        swipeable={true}
        emulateTouch={true}
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={true}
        centerMode={true}
        centerSlidePercentage={100}
        className="customized-carousel carousel-small md:hidden block"
      >
        {posts.map((post) => {
          const { id, title, image, subPath } = post;
          const pathToImage = getImage(image);
          const slug = slugify(subPath, { lower: true });

          return (
            <div
              key={id}
              className="shadow-lg drop-shadow-xl mx-4 md:mx-0 md:ml-12 rounded-xl aspect-square mb-8"
            >
              <Link to={`/${slug}/`}>
                <GatsbyImage
                  image={pathToImage}
                  alt={title}
                  className={`${smooth} aspect-square relative image-swiper brightness-[95%] group-hover:brightness-100 rounded-xl`}
                />
              </Link>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default HighlightPosts;
