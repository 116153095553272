import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import slugify from "slugify";

const aniSmooth = "transition-all duration-[0.3s] ease-in-out";

const query = graphql`
  {
    allContentfulPost(
      sort: { fields: date, order: DESC }
      filter: { editorsPick: { eq: true } }
    ) {
      nodes {
        id
        title
        subPath
        date(formatString: "DD MMMM, YYYY")
        description {
          description
        }
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
  }
`;

const HighlightPosts = () => {
  const {
    allContentfulPost: { nodes: posts },
  } = useStaticQuery(query);
  return (
    <div>
      <div>
        {posts.slice(0, 2).map((post) => {
          const {
            id,
            title,
            image,
            subPath,
            date,
            description: { description },
          } = post;
          const pathToImage = getImage(image);
          const slug = slugify(subPath, { lower: true });
          return (
            <div
              key={id}
              className="group relative flex flex-col h-full w-full mr-6"
            >
              <Link
                to={`/${slug}/`}
                className={`${aniSmooth} group-hover:-translate-y-1 group-hover:drop-shadow-lg h-full w-full object-contain`}
              >
                <div className="relative z-10 h-full w-full object-contain">
                  <GatsbyImage
                    image={pathToImage}
                    alt={title}
                    className={`${aniSmooth} rounded-r-xl border-l-4 border-green-120 aspect-square object-contain w-[250px] group-hover:brightness-[100%] brightness-[90%]`}
                  />
                </div>
              </Link>

              <div className="w-full flex justify-start items-center pt-2 pb-1.5">
                <div className="h-[2px] w-[40px] bg-stone-200 mr-4 rounded-xl"></div>
                <div className="font-jarkatar font-base text-xs text-stone-400">
                  {date}
                </div>
              </div>

              <Link
                className={`${aniSmooth} group-hover:text-green-120 font-headlao flex justify-start items-start font-medium text-base mb-2 h-auto text-ellipsis overflow-hidden text-left`}
                to={`/${slug}/`}
              >
                {title}
              </Link>

              <div className="truncate mb-10 md:mb-6 text-sm text-justify font-base text-stone-400 font-smlao">
                {description}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HighlightPosts;
