import React from "react";
import { Link } from "gatsby";
const hover = "transition-all ease-in-out duration-200 ";

const Tags = ({
  tagsBiodiversity,
  tagsBusinessAndSustainability,
  tagsClimateChange,
  tagsEnglishNews,
  tagsGreenInnovation,
  tagsNaturalDisaster,
  tagsPlasticAndWaste,
  tagsWildlife,
}) => {
  return (
    <div
      className={`flex flex-wrap justify-start lg:justify-end lg:ml-2 w-full `}
    >
      <Link
        to={"/publication/englishnews/"}
        className={`${hover} ${
          tagsEnglishNews ? "flex" : "hidden"
        } m-0.5 bg-stone-100 text-indigo-600/60  hover:text-green-120 hover:drop-shadow font-jarkatar font-medium text-xs px-1.5 py-0.5 rounded-xl`}
      >
        #englishNews
      </Link>
      <Link
        to={"/publication/climatechange/"}
        className={`${hover} ${
          tagsClimateChange ? "flex" : "hidden"
        } m-0.5 bg-stone-100 text-orange-600/60  hover:text-green-120 hover:drop-shadow font-jarkatar font-medium text-xs px-1.5 py-0.5 rounded-xl`}
      >
        #climateChange
      </Link>
      <Link
        to={"/publication/wildlife/"}
        className={`${hover} ${
          tagsWildlife ? "flex" : "hidden"
        } m-0.5 bg-stone-100 text-lime-600/80 hover:text-green-120 hover:drop-shadow font-jarkatar font-medium text-xs px-1.5 py-0.5 rounded-xl`}
      >
        #wildLife
      </Link>
      <Link
        to={"/publication/plasticandwastepollution/"}
        className={`${hover} ${
          tagsPlasticAndWaste ? "flex" : "hidden"
        } m-0.5 bg-stone-100 text-cyan-500/60  hover:text-green-120 hover:drop-shadow font-jarkatar font-medium text-xs px-1.5 py-0.5 rounded-xl`}
      >
        #plasticAndPollutionWaste
      </Link>
      <Link
        to={"/publication/naturaldisaster/"}
        className={`${hover} ${
          tagsNaturalDisaster ? "flex" : "hidden"
        } m-0.5 bg-stone-100 text-red-600/60 hover:text-green-120 hover:drop-shadow font-jarkatar font-medium text-xs px-1.5 py-0.5 rounded-xl`}
      >
        #naturalDisaster
      </Link>
      <Link
        to={"/publication/biodiversity/"}
        className={`${hover} ${
          tagsBiodiversity ? "flex" : "hidden"
        } m-0.5 bg-stone-100 text-green-400 hover:text-green-120 hover:drop-shadow font-jarkatar font-medium text-xs px-1.5 py-0.5 rounded-xl`}
      >
        #biodiversity
      </Link>
      <Link
        to={"/publication/businessandsustainability/"}
        className={`${hover} ${
          tagsBusinessAndSustainability ? "flex" : "hidden"
        } m-0.5 bg-stone-100 text-purple-500/60 hover:text-green-120 hover:drop-shadow font-jarkatar font-medium text-xs px-1.5 py-0.5 rounded-xl`}
      >
        #businessAndSustainability
      </Link>
      <Link
        to={"/publication/greeninnovation/"}
        className={`${hover} ${
          tagsGreenInnovation ? "flex" : "hidden"
        } m-0.5 bg-stone-100 text-lime-500 hover:text-green-120 hover:drop-shadow font-jarkatar font-medium text-xs px-1.5 py-0.5 rounded-xl`}
      >
        #greenInnovation
      </Link>
    </div>
  );
};

export default Tags;
