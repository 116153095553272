import React from "react";
import HighlightPosts from "./HighlightPosts";
import EditorPosts from "./EditorPosts";
import { Link } from "gatsby";
import { GoBookmark } from "@react-icons/all-files/go/GoBookmark";

const aniSmooth = "transition-all duration-[0.3s] ease-in-out";

const Landing = () => {
  return (
    <div className="w-full 2xl:w-[1440px] h-full flex flex-col md:flex-row justify-start items-start">
      <div
        className={`w-full md:w-[60%] h-full justify-center items-center pb-[3rem] md:pb-16 `}
      >
        <div className="flex justify-start items-center mb-6 md:pl-0 ">
          <div className="flex justify-start items-center w-full mx-4 lg:mx-0 pb-4 md:pb-0 border-b-2 md:border-y-0 border-stone-200 md:pl-14">
            <div className="w-[7px] h-[35px] bg-green-120 bg opacity-80"></div>
            <div className="font-cardo font-bold text-3xl text-stone-900 pl-4 cursor-default flex">
              Today Highlights
            </div>
          </div>
        </div>
        <HighlightPosts />
      </div>

      <div className="hidden md:flex h-[600px] w-[3px] bg-stone-300 bg-opacity-[50%] mr-14 rounded-xl"></div>

      <div className={`w-full md:w-[40%] h-[100%] px-4 md:pr-10`}>
        <div className="flex justify-start items-center pb-4 border-b-2 md:border-y-0 border-stone-200">
          <div className="w-[7px] h-[35px] bg-green-120 bg opacity-80"></div>
          <div className="font-cardo font-bold text-3xl text-stone-900 pl-4 w-full flex">
            <div className="w-2/3 flex justify-start items-center cursor-default">
              Editor's Picks
              <GoBookmark className="text-xl ml-1.5 mt-0 text-orange-400" />
            </div>
            <Link
              to="/publication/"
              className={`${aniSmooth} hover:text-green-120 hover:decoration-green-120 w-1/3 flex justify-end items-center font-jarkatar font-medium text-[0.6rem] md:text-[0.75rem] text-stone-700 decoration-stone-700 underline underline-offset-4`}
            >
              more articles
            </Link>
          </div>
        </div>
        <div className="w-full mt-6 md:mt-3">
          <EditorPosts />
        </div>
      </div>
    </div>
  );
};

export default Landing;
