import * as React from "react";
import Navbar from "../components/Navbar";
// import LazyShow from "../utilities/LazyShow";
import ButtonUp from "../utilities/ButtomUp";
import Landing from "../components/Landing";
import Banner from "../components/Banner";
import LatestPosts from "../components/LatestPosts";
import Footer from "../components/Footer";
import Seo from "../components/Seo";

const IndexPage = () => {
  return (
    <div className="relative overflow-hidden no-scrollbar bg-stone-50">
      <Seo title="Home" pathname="/" />
      <div id="top" />
      <Navbar />
      <div className="z-10 relative w-screen flex justify-center items-center mt-2 md:mt-6">
        <Landing />
      </div>
      <div className="relative w-screen flex justify-center items-center">
        <Banner />
      </div>
      <div id="latest" className="h-10" />
      <div className="relative w-screen flex justify-center items-center">
        <LatestPosts />
      </div>
      {/* <LazyShow x={-100}> */}
      <div className="relative w-screen flex justify-center items-center">
        <Footer />
      </div>
      {/* </LazyShow> */}
      <ButtonUp link={"#top"} />
    </div>
  );
};

export default IndexPage;
