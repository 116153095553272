import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import slugify from "slugify";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { AiFillCaretLeft } from "@react-icons/all-files/ai/AiFillCaretLeft";
import { AiFillCaretRight } from "@react-icons/all-files/ai/AiFillCaretRight";
import Tags from "./Tags";

const hover = "transition-all ease-in-out duration-200 ";

const query = graphql`
  {
    allContentfulPost(sort: { fields: date, order: DESC }, limit: 12) {
      nodes {
        id
        title
        subPath
        date(formatString: "DD MMMM, YYYY")
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
        tagsBiodiversity
        tagsBusinessAndSustainability
        tagsClimateChange
        tagsEnglishNews
        tagsGreenInnovation
        tagsNaturalDisaster
        tagsPlasticAndWaste
        tagsWildlife
      }
    }
  }
`;

const LatestPosts = () => {
  const [indexN, setIndexN] = useState(0);
  const postPerPage = 6;
  const {
    allContentfulPost: { nodes: posts },
  } = useStaticQuery(query);

  const next = () => {
    indexN + 1 >= Math.ceil(10 / postPerPage)
      ? setIndexN((prevEff) => prevEff)
      : setIndexN((prevEff) => prevEff + 1);
  };

  const back = () => {
    indexN + 1 <= 1
      ? setIndexN((prevEff) => prevEff)
      : setIndexN((prevEff) => prevEff - 1);
  };

  return (
    <div
      className={`w-full 2xl:w-[1440px] h-full flex flex-col justify-center items-start scroll-smooth`}
    >
      <div className="flex justify-start items-center w-full px-4 md:px-14">
        <div className="w-full flex justify-center border-b-2 md:border-y-0 border-stone-200 py-4">
          <div className="flex w-2/3 justify-start items-center">
            <div className="w-[7px] h-[35px] bg-green-120 opacity-80"></div>
            <div className="font-cardo font-bold text-3xl text-stone-900 pl-4 flex justify-center items-center">
              Latest Articles
            </div>
          </div>
          <div className="flex w-1/3 justify-end items-center">
            <Link
              to="/publication/"
              className={`${hover} hover:text-green-120 hover:decoration-green-120"flex justify-end items-center font-jarkatar font-medium text-[0.6rem] md:text-[0.75rem] text-stone-700 decoration-stone-700 hover:decoration-green-120 underline underline-offset-4`}
            >
              more articles
            </Link>
          </div>
        </div>
      </div>

      <div
        className={`${hover} w-full pt-4 z-10 h-[100%] px-4 md:pl-10  flex flex-wrap items-center justify-start pb-0 lg:pb-2`}
      >
        {posts
          .slice(indexN * postPerPage, (indexN + 1) * postPerPage)
          .map((post) => {
            const {
              id,
              title,
              image,
              subPath,
              date,
              tagsBiodiversity,
              tagsBusinessAndSustainability,
              tagsClimateChange,
              tagsEnglishNews,
              tagsGreenInnovation,
              tagsNaturalDisaster,
              tagsPlasticAndWaste,
              tagsWildlife,
            } = post;
            const pathToImage = getImage(image);
            const slug = slugify(subPath, { lower: true });
            return (
              <div
                key={id}
                className={`${hover} group mx-4 mb-8 scale-[100%] md:scale-[96%] relative h-full w-[100%] sm:w-[45%] lg:w-[30%] px-6 py-8 bg-white drop-shadow-lg`}
              >
                <div
                  className={`${hover} relative z-10 w-full h-full flex flex-col justify-start items-start`}
                >
                  <Link to={`/${slug}/`} className="object-contain">
                    <GatsbyImage
                      image={pathToImage}
                      alt={title}
                      className={`${hover} aspect-square h-full w-full group-hover:brightness-[100%] brightness-[90%]`}
                    />
                  </Link>

                  <Link
                    to={`/${slug}/`}
                    className={`${hover} text-lg font-headlao hover:text-green-120 text-ellipsis overflow-hidden my-3 text-left h-14`}
                  >
                    {title}
                  </Link>

                  <div className="w-full flex justify-start items-center pb-3">
                    <div className="h-[2px] w-[25px] bg-stone-200 mr-2 rounded-xl"></div>
                    <div className="font-jarkatar font-base text-xs text-stone-400">
                      {date}
                    </div>
                  </div>

                  <div className="w-full">
                    <Tags
                      tagsBiodiversity={tagsBiodiversity}
                      tagsBusinessAndSustainability={
                        tagsBusinessAndSustainability
                      }
                      tagsClimateChange={tagsClimateChange}
                      tagsEnglishNews={tagsEnglishNews}
                      tagsGreenInnovation={tagsGreenInnovation}
                      tagsNaturalDisaster={tagsNaturalDisaster}
                      tagsPlasticAndWaste={tagsPlasticAndWaste}
                      tagsWildlife={tagsWildlife}
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      <div className={`px-6 md:px-12 w-full`}>
        <div className="flex justify-center lg:justify-end items-center border-stone-200 border-b-2 pb-8 lg:pb-12 mb-12">
          <AnchorLink to="/#latest">
            <button
              className={` ${hover} mt-2 px-2 text-stone-300 hover:text-green-120 text-xl`}
              onClick={() => back()}
              aria-label="back"
            >
              <AiFillCaretLeft />
            </button>
          </AnchorLink>
          {posts.slice(0, Math.ceil(10 / postPerPage)).map((post, index) => {
            const { id } = post;
            return (
              <AnchorLink
                to="/#latest"
                key={id}
                className={` ${hover} ${
                  index === indexN ? "text-green-120" : "text-stone-300"
                } mx-2 flex justify-center items-center hover:text-stone-700`}
              >
                <button
                  className="font-jarkatar font-medium text-base"
                  onClick={() => setIndexN(index)}
                >
                  {index + 1}
                </button>
              </AnchorLink>
            );
          })}
          <AnchorLink to="/#latest">
            <button
              className={`${hover} mt-2 px-2 text-stone-300 hover:text-green-120 text-xl`}
              onClick={() => next()}
              aria-label="next"
            >
              <AiFillCaretRight />
            </button>
          </AnchorLink>
        </div>
      </div>
    </div>
  );
};

export default LatestPosts;
